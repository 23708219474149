import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import classnames from "classnames";
import Img from "gatsby-image";
import cssClass from "./about.module.scss";

const About = ({ documentScrollPosition, height }) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "about-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 4200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      photo: file(relativePath: { eq: "about-photo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const classes = classnames(cssClass.content, {
    [cssClass.show]: documentScrollPosition >= height * 2 - 400
  });

  return (
    <div className={cssClass.about}>
      <Img fluid={data.background.childImageSharp.fluid} className={cssClass.background} />
      <div className={classes}>
        <p>Join Alba as she visits her grandparents on a Mediterranean island. She is ready for a peaceful summer of wildlife exploration with her friend Ines, but when she sees an animal in danger, she realises she needs to do something about it!</p>
        <Img fluid={data.photo.childImageSharp.fluid} className={cssClass.photo} />
      </div>
    </div>
  );
};

export default About;
