import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import classnames from "classnames";
import imageSun from "../assets/feature-sun.svg";
import imageMusic from "../assets/feature-music.svg";
import imageTick from "../assets/feature-tick.svg";
import imageHeart from "../assets/feature-heart.svg";
import cssClass from "./features.module.scss";

const Features = ({ documentScrollPosition, height }) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "features-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 4200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const classes = classnames(cssClass.items, {
    [cssClass.show]: documentScrollPosition >= height * 3 - 500
  });

  return (
    <div className={cssClass.features}>
      <Img fluid={data.background.childImageSharp.fluid} className={cssClass.background} />
      <ul className={classes}>
        <li className={`${cssClass.item} ${cssClass.itemTick}`}>
          <img className={cssClass.icon} src={imageTick} alt="" />
          <h2 className={cssClass.title}>A place you want to stay</h2>
          <p>We based this on our childhood summers and, well, we wish we could go back. This island is the next best thing</p>
        </li>
        <li className={`${cssClass.item} ${cssClass.itemSun}`}>
          <img className={cssClass.icon} src={imageSun} alt="" />
          <h2 className={cssClass.title}>This is chillectathon</h2>
          <p>Make your time on the island your own and experience the adventure at your own pace. No rush.</p>
        </li>
        <li className={`${cssClass.item} ${cssClass.itemMusic}`}>
          <img className={cssClass.icon} src={imageMusic} alt="" />
          <h2 className={cssClass.title}>Great Music</h2>
          <p>We worked with Lorena Alvarez, to make a soundtrack that is equal parts Spain and Amazing </p>
        </li>
        <li className={`${cssClass.item} ${cssClass.itemHeart}`}>
          <img className={cssClass.icon} src={imageHeart} alt="" />
          <h2 className={cssClass.title}>Feel-good game</h2>
          <p>Honestly, a feel-good game about running around and doing good deeds</p>
        </li>
      </ul>
    </div>
  );
};

export default Features;
