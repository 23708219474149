import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import cssClass from "./top.module.scss";

const Top = ({ documentScrollPosition, isMobile }) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "desktop-parallax-header-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      foreground: file(relativePath: { eq: "desktop-parallax-header-foreground.png" }) {
        childImageSharp {
          fluid(maxWidth: 4200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      backgroundMobile: file(relativePath: { eq: "mobile-parallax-header-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      foregroundMobile: file(relativePath: { eq: "mobile-parallax-header-foreground.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      albaLogo: file(relativePath: { eq: "alba-game-ustwo-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      steamLogo: file(relativePath: { eq: "logo-steam.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      appleArcadeLogo: file(relativePath: { eq: "logo-appleArcade.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      playstationLogo: file(relativePath: { eq: "logo-playstation.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      nintendoLogo: file(relativePath: { eq: "logo-nintendo-switch.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      xBoxLogo: file(relativePath: { eq: "logo-xbox.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  
  const bgImageBgStyles = {
    transform: `translate3d(0, calc(-50% - ${documentScrollPosition * 0.01}vw), 0) scale(1.05)`
  };

  const h1Styles = {
    transform: `translate3d(0, -${documentScrollPosition * 0.03}vh, 0)`
  };

  const pStyles = {
    transform: `translate3d(0, -${documentScrollPosition * 0.02}vh, 0)`
  };

  return (
    <div className={cssClass.top}>
      {isMobile ? <Img fluid={data.backgroundMobile.childImageSharp.fluid} className={cssClass.backgroundImageBackground} style={bgImageBgStyles} /> : <Img fluid={data.background.childImageSharp.fluid} className={cssClass.backgroundImageBackground} style={bgImageBgStyles} />}
      {isMobile ? <Img fluid={data.foregroundMobile.childImageSharp.fluid} className={cssClass.backgroundImageForeground} /> : <Img fluid={data.foreground.childImageSharp.fluid} className={cssClass.backgroundImageForeground} />}
      <div className={cssClass.textContent}>
        <h1 style={h1Styles} className={cssClass.logo}><Img fluid={data.albaLogo.childImageSharp.fluid} alt="Alba: a wildlife adventure" />Alba: a wildlife adventure</h1>
        <p style={pStyles}>Out now on PC, Apple Arcade and Console.</p>
      </div>
      <div className={cssClass.content}>
        <div className={cssClass.links}>
          <a href="https://apps.apple.com/app/alba-a-wildlife-adventure/id1528014682">
            <Img fluid={data.appleArcadeLogo.childImageSharp.fluid} alt="Apple Arcade Logo" />
          </a>
          <a href="https://store.steampowered.com/app/1337010/Alba_A_Wildlife_Adventure/">
            <Img fluid={data.steamLogo.childImageSharp.fluid} alt="Steam Logo" />
          </a>
          <a href="https://store.playstation.com/en-gb/product/EP1302-CUSA27085_00-ALBAADVENTUREPS4" className={cssClass.playstation}>
            <Img fluid={data.playstationLogo.childImageSharp.fluid} alt="Playstation Logo" />
          </a>
          <a href="https://www.nintendo.com/games/detail/alba-a-wildlife-adventure-switch/" className={cssClass.nintendo}>
            <Img fluid={data.nintendoLogo.childImageSharp.fluid} alt="Nintendo Logo" />
          </a>
          <a href="https://www.microsoft.com/store/productid/9nc05cgpwg0z" className={cssClass.xbox}>
            <Img fluid={data.xBoxLogo.childImageSharp.fluid} alt="Xbox Logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Top;
