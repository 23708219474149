import React, { useState, createRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import classnames from "classnames";
import Img from "gatsby-image";
import trailer from "../assets/alba-trailer.mp4";
import cssClass from "./video.module.scss";

const Video = ({ isMobile }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "video-placeholder.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const videoRef = createRef();
  const [videoPlaying, setVideoPlaying] = useState(false);


  const handleClick = (e) => {
    e.preventDefault();
    setVideoPlaying(true);
    videoRef.current.play();
  }

  const handlePause = (e) => {
    e.preventDefault();
    setVideoPlaying(false);
    videoRef.current.pause();
  }

  const overlayClasses = classnames(cssClass.overlay, {
    [cssClass.playing]: videoPlaying
  });

  return (
    <div className={cssClass.video}>
      <video
        src={trailer}
        playsInline
        onClick={handlePause}
        autoPlay={videoPlaying}
        ref={videoRef}
        preload={isMobile ? "none" : "auto"}
        controls
        type="video/mp4"
      >
        <track kind="captions" />
      </video>
      <button 
        className={overlayClasses}
        onClick={handleClick}
        onKeyPress={handleClick}
        tabIndex="0"
        aria-label="Play video"
      >
        {data.file && <Img fluid={data.file.childImageSharp.fluid} alt="" className={cssClass.overlayImage} />}
        <div className={cssClass.playButton}>
          <div className={cssClass.circle} />
          <div className={cssClass.label}>Play video</div>
        </div>
      </button>
    </div>
  );
}

export default Video;
