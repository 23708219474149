import React, { Component } from "react";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import Layout from "../components/layout";
import Menu from "../components/menu";
import Section from "../components/section";
import sections from "../content/sections";
import cssClass from "./index.module.scss";

class Home extends Component {
  constructor() {
    super();

    this.getViewportDimensions = this.getViewportDimensions.bind(this);
    this.getDocumentScrollPosition = this.getDocumentScrollPosition.bind(this);

    this.state = {
      height: 0,
      width: 0,
      documentScrollPosition: 0,
      isMobile: false,
      isTablet: false
    };
  }

  componentDidMount() {
    this.getViewportDimensions();
    window.addEventListener("resize", this.getViewportDimensions, false);
    window.addEventListener("scroll", this.getDocumentScrollPosition, {
      passive: true
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getViewportDimensions, false);
    window.removeEventListener("scroll", this.getDocumentScrollPosition, {
      passive: true
    });
  }

  getViewportDimensions() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
      isMobile: window.innerWidth < 768,
      isTablet: window.innerWidth < 1024
    });
  }

  getDocumentScrollPosition() {
    this.setState({
      documentScrollPosition: document.scrollingElement.scrollTop
    });
  }

  render() {
    const { documentScrollPosition, height } = this.state;
    const metaTitle = "Alba: a wildlife adventure by ustwo games";
    const metaDescription = "The Official Website for 'Alba: a Wildlife Adventure' from ustwo games.";
    const metaImage = "//www.albawildlife.com/alba-a-wildlife-adventure-ustwo.jpg";

    const renderSections = sections.map((section) => {
      const Component = section.component;
      const classes = classnames(cssClass.section, cssClass[section.name]);

      return (
        <Section className={classes} key={section.name} id={section.name}>
          <Component {...this.state} />
        </Section>
      );
    });

    const sectionChangeForMenu = Math.ceil((documentScrollPosition + (height * 0.25)) / height);
    const menuActiveSectionNumber = isFinite(sectionChangeForMenu) ? sectionChangeForMenu : 1; // Fix infinity on load
    const fixNumber = menuActiveSectionNumber < 1 ? 1 : menuActiveSectionNumber;
    const menuActiveSection = menuActiveSectionNumber > sections.length ? sections[sections.length - 1].name : sections[fixNumber - 1].name;


    return (
      <Layout>
        <Helmet>
          <html lang="en" />
          {metaTitle && <title>{metaTitle}</title>}
          {metaDescription && <meta name="description" content={metaDescription} />}
          {metaTitle && <meta property="og:title" content={metaTitle} />}
          {metaDescription && <meta name="og:description" content={metaDescription} />}
          {metaImage && <meta property="og:image" content={metaImage} />}
          {metaTitle && <meta name="twitter:title" content={metaTitle} />}
          {metaDescription && <meta name="twitter:description" content={metaDescription} />}
          {metaImage && <meta name="twitter:image" content={`https:${metaImage}`} />}
          {metaImage && <meta name="twitter:image:src" content={`https:${metaImage}`} />}
          <meta name="twitter:card" content="summary_large_image" />
          <link rel="preconnect" href="fonts.googleapis.com" />
        </Helmet>
        <Menu activeSection={menuActiveSection} />
        {renderSections}
      </Layout>
    );
  }
}

export default Home;
