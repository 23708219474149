import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import classnames from "classnames";
import cssClass from "./awards.module.scss";

const Awards = ({ documentScrollPosition, height }) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "awards-background-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      wreathLeft: file(relativePath: { eq: "wreath-left.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      wreathRight: file(relativePath: { eq: "wreath-right.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      pocketGamer: file(relativePath: { eq: "pocketgamer.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const classes = classnames(cssClass.items, {
    [cssClass.show]: documentScrollPosition >= height * 5 - 500
  });

  return (
    <div className={cssClass.awards}>
      <Img fluid={data.background.childImageSharp.fluid} className={cssClass.background} />
      <ul className={classes}>
        <li className={cssClass.item}>
          <p>&quot;A terrific experience, imbued with personality at every turn, that can be polished off in a single session.&quot;</p>
          <p className={cssClass.source}><strong>9/10</strong> - <a href="https://steamcommunity.com/linkfilter/?url=https://fingerguns.net/reviews/2020/12/11/alba-a-wildlife-adventure-review-pc-no-reservations/">Finger Guns</a></p>
        </li>
        <li className={cssClass.item}>
          <p>&quot;Not only have ustwo created a world that is stunning to look at, but they’ve crafted a narrative that boldly shouts that this girl can.&quot;</p>
          <p className={cssClass.source}><strong>9/10</strong> - <a href="https://steamcommunity.com/linkfilter/?url=https://www.thesixthaxis.com/2020/12/11/alba-a-wildlife-adventure-review/">The Sixth Axis</a></p>
        </li>
        <li className={cssClass.item}>
          <p>&quot;The story and gameplay for Alba: A Wildlife Adventure is both delightful and eye-opening for anyone who decides to pick up a camera and hunt for that perfect lynx picture.&quot;</p>
          <p className={cssClass.source}><strong>4/5</strong> - <a href="https://steamcommunity.com/linkfilter/?url=https://screenrant.com/alba-wildlife-adventure-game-review/">Screen Rant</a></p>
        </li>
        <li className={cssClass.item}>
          <div className={cssClass.award}>
            <Img fluid={data.wreathLeft.childImageSharp.fluid} className={cssClass.wreath} />
            <div>BEST APPLE <br />ARCADE GAME</div>
            <Img fluid={data.wreathRight.childImageSharp.fluid} className={cssClass.wreath} />
          </div>
          <a href="https://www.pocketgamer.com/pgawards2021/winners/best-apple-arcade-game/"><Img fluid={data.pocketGamer.childImageSharp.fluid} className={cssClass.pocketGamer} /></a>
          <p className={cssClass.awardName}>Awards 2021</p>
        </li>
      </ul>
    </div>
  );
};

export default Awards;
