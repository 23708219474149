import React from "react";
import classnames from "classnames";
import cssClass from "./section.module.scss";

const Section = ({ children, className, id }) => {
  return (
    <section className={classnames(cssClass.section, className)} id={id}>
      {children}
    </section>
  );
};

export default Section;
