import React, { Component } from "react";
import classnames from "classnames";
import Scrollchor from "react-scrollchor";
import sections from "../content/sections";
import cssClass from "./menu.module.scss";

class Menu extends Component {
  constructor() {
    super();

    this.state = {
      menuClick: false
    }

    this.onMenuClick = this.onMenuClick.bind(this);
  }

  /* 
    This is used to add a css class which delays the transition in of a menu item
    as it looks messy seeing a glimpse of each menu item when the target is 2 or more
    sections scrolling away.
  */
  onMenuClick() {
    this.setState({
      menuClick: true
    });
    setTimeout(() => {
      this.setState({
        menuClick: false
      });
    }, 600);
  }
  
  render() {
    const { activeSection } = this.props;
    const { menuClick } = this.state;

    const renderItems = sections.map(section => {
      const itemClasses = classnames(cssClass.menuItem, {
        [cssClass.active]: activeSection === section.name,
        [cssClass.menuClick]: menuClick
      });

      return (
        <li className={itemClasses} key={section.name}>
          <Scrollchor to={section.name} beforeAnimate={this.onMenuClick} className={cssClass.bullet}>
            <span />
          </Scrollchor>
          <div className={cssClass.label}>{section.name}</div>
        </li>
      )
    });

    return (
      <nav className={cssClass.menu}>
        <ul>
          {renderItems}
        </ul>
      </nav>
    );
  }
}

export default Menu;