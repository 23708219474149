import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Slider from "react-slick";
import Img from "gatsby-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slider.css";
import cssClass from "./gallery.module.scss";

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "gallery/Castle_tvOS_3840x2160.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image2: file(relativePath: { eq: "gallery/Forest_tvOS_3840x2160.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image3: file(relativePath: { eq: "gallery/NR_AppleTV_3840x2160.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image4: file(relativePath: { eq: "gallery/Scanner_tvOS_3840x2160.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image5: file(relativePath: { eq: "gallery/Town_tvOS_3840x2160.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image6: file(relativePath: { eq: "gallery/screenshot1.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image7: file(relativePath: { eq: "gallery/screenshot2.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image8: file(relativePath: { eq: "gallery/screenshot3.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image9: file(relativePath: { eq: "gallery/screenshot4.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image10: file(relativePath: { eq: "gallery/screenshot5.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  const images = [
    data.image1, 
    data.image2,
    data.image3,
    data.image4,
    data.image5,
    data.image6,
    data.image7,
    data.image8,
    data.image9,
    data.image10,
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '300px',
    className: cssClass.carousel,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: '200px'
        }
      },
      {
        breakpoint: 1280,
        settings: {
          centerPadding: '100px'
        }
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '50px'
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '20px'
        }
      }
    ]
  };

  return (
    <div className={cssClass.gallery}>
      <Slider {...settings}>
        {images.map(image => (
          <div className={cssClass.slide} key={image.id}>
            <Img fluid={image.childImageSharp.fluid} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Gallery;
